import { Component, OnInit } from "@angular/core";

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-baskte-modal",
  templateUrl: "./baskte-modal.page.html",
  styleUrls: ["./baskte-modal.page.scss"],
})
export class BaskteModalPage implements OnInit {
  name;
  public basketForm: FormGroup;
  submitted = false;
  constructor(
    public modalCtrl: ModalController,
    private fb: FormBuilder
  ) {
    this.basketForm = this.fb.group({
      uid: new FormControl(0),
      firmName: new FormControl("", [
        Validators.required,
        Validators.minLength(1),
        Validators.pattern(/^((?!\s{2,}).)*$/),
      ],),
      street: new FormControl("", [
        Validators.required,
        Validators.minLength(1),
        Validators.pattern(/^((?!\s{2,}).)*$/),
      ],),
      ort: new FormControl("", [
        Validators.required,
        Validators.minLength(1),
        Validators.pattern(/^((?!\s{2,}).)*$/),
      ],),
      Plz: new FormControl("", [
        Validators.required,
        Validators.minLength(1),
        Validators.pattern(/^((?!\s{2,}).)*$/),
      ],),
      land: new FormControl("", [
        Validators.required,
        Validators.minLength(1),
        Validators.pattern(/^((?!\s{2,}).)*$/),
      ],),
      email: new FormControl("", [
        Validators.required,
        Validators.minLength(1),
        Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/),
      ],),
    });
    Object.keys(this.basketForm.controls).forEach((key) => {
      this.basketForm.controls[key].valueChanges.subscribe((value) => {
        if (typeof value === 'string') {
          this.basketForm.controls[key].setValue(value.trim(), { emitEvent: false });
        }
      });
    });
  }

  ngOnInit() {
    let appcmsUser = JSON.parse(window.localStorage.getItem('appcmsUser'));
    this.basketForm.get('land').setValue("Deutschland");

    if (appcmsUser.company != null) {
      this.basketForm.get('uid').setValue(appcmsUser.company.uid);
      this.basketForm.get('firmName').setValue(appcmsUser.company.firmName);
      this.basketForm.get('street').setValue(appcmsUser.company.street);
      this.basketForm.get('ort').setValue(appcmsUser.company.ort);
      this.basketForm.get('land').setValue(appcmsUser.company.land);
      this.basketForm.get('email').setValue(appcmsUser.company.email);
      this.basketForm.get('Plz').setValue(appcmsUser.company.plz);
    }
  }
  setval(val) {
  }
  dismiss() {
    this.modalCtrl.dismiss();
  }
  saveDetails() {
    this.submitted = true;
    // // stop here if form is invalid
    //this.basketForm.controls['Plz']
    if (this.basketForm.invalid) {
      this.basketForm.markAllAsTouched();
      return;
    }
    this.modalCtrl.dismiss(this.basketForm.value);
  }


  disablebtn() {
    let disablebtn = true;
    disablebtn = (this.basketForm.controls["firmName"].value == "" ||
      this.basketForm.controls["street"].value == "" ||
      this.basketForm.controls["email"].value == "" ||
      this.basketForm.controls["ort"].value == "" ||
      this.basketForm.controls["Plz"].value == "") ? true : false;
    return disablebtn;
  }

  getFormValues($event, key) {
    var ev = $event.target.value;
    switch (key) {
      case "firmName":
        this.basketForm.patchValue({ firmName: ev });
        break;
      case "street":
        this.basketForm.patchValue({ street: ev });
        break;
      case "ort":
        this.basketForm.patchValue({ ort: ev });
        break;
      case "Plz":
        this.basketForm.patchValue({ Plz: ev });
        break;
      case "land":
        this.basketForm.patchValue({ land: ev });
        break;
      case "email":
        this.basketForm.patchValue({ email: ev });
        break;
    }
  }
}
