import { Injectable } from '@angular/core';
import { AppcmsService } from './appcms.service';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  paymentMethodCodes: any = {
    //'Apple Pay': 'applepay',
    'Barzahlung': 'cod',
    'Rechnung': 'invoice',
    'PayPal': 'paypal',
    'klarna': 'klarna',
    'Mollie':'mollie'
  };

  paymentMethods: any = [
    {
       id: 'applepay',
       icon: 'logo-apple',
       title: 'Apple Pay',
    },
    {
      id: 'paypal',
      image: './assets/img/paypal.png',
      title: 'PayPal'
    },
    {
      id: 'klarna',
      image: './assets/img/klarna.png',
      title: 'klarna'
    },
    {
      id: 'Barzahlung',
      icon: 'cash',
      title: 'Barzahlung',
    },
    {
      id: 'Rechnung',
      icon: 'document',
      title: 'Rechnung',
    },
    {id:'mollie',
       icons:'Mollie',
       title: 'Mollie',
    }
  ];

  paymentOptions: any = {
    paymentMethod: window.localStorage.getItem('paymentMethod') || '',
  };

  constructor(
    public AppCMS: AppcmsService,
    public cache: CacheService,
  ) {

  }

  getPaymentMethod() {
    return this.getPaymentOptions().paymentMethod;
  }

  getPaymentMethodCode() {
    return this.paymentMethodCodes[this.getPaymentMethod()] || null;
  }

  getPaymentMethods() {
    return this.paymentMethods;
  }

  getPaymentMethodTitle() {
    return this.getPaymentMethod();
  }

  getPaymentOptions() {
    return this.paymentOptions;
  }

  paid() {
    let blPaid = false;
    switch(this.getPaymentMethod()) {
      case 'Apple Pay':
        blPaid = true;
        break;
      case 'Barzahlung':
        blPaid = false;
        break;
      case 'klarna':
        blPaid = true;
        break;
      case 'Kundenkarte':
        blPaid = false;
        break;
      case 'PayPal':
        blPaid = true;
        break;
      case 'Rechnung':
        blPaid = false;
        break;
        case 'Mollie':
        blPaid = true;
        break;
    }
    return blPaid;
  }

  setPaymentMethod(paymentMethod: string) {
    this.paymentOptions.paymentMethod = paymentMethod;
    window.localStorage.setItem('paymentMethod', paymentMethod);
  }

  getInvoiceAllow(tenantId,memberId) {
   
    return new Promise((resolve, reject) => {
        this.AppCMS.loadUrl(`member/GetInvoicePaymentPermission?tenantId=${tenantId}&memberId=${memberId}`, {})
        .then((value: any) => {

          // if(taxiSettings.theme) {
          //   this.theme.update(taxiSettings.theme);
          // }

        
          resolve(value);
        })
        .catch(reject);
      // }
    });
  }

}
