import { Injectable } from '@angular/core';
import { AppcmsService } from './appcms.service';

declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class DistancesService {

  //protected apiKey: string = 'AIzaSyDq2kA0Fm4jAn5Di8nBu6Wtkp6JR7wtE0Y';
  protected apiKey: string = 'AIzaSyDjAv5-53CN-G9xnpqqwM9IGLqxgX4J2Fw';

  geocoder: any;

  constructor(
    public AppCMS: AppcmsService,
  ) {

  }

  calculate(origin: string, destination: string) {
    return new Promise((resolve, reject) => {
      var service = new google.maps.DistanceMatrixService();
      service.getDistanceMatrix({
        origins: [origin],
        destinations: [destination],
        travelMode: 'DRIVING',
      }, (response: any, status: any) => {
        if (status === 'OK') {
          resolve(
            response && response.rows && response.rows[0] && response.rows[0].elements && response.rows[0].elements[0] ?
              response.rows[0].elements[0] :
              null
          );
        } else {
          reject(response);
        }
      });
    });
  }

  filter(items: any, origin: string, distance = 10000) {
    return new Promise((resolve, reject) => {
      if (!origin) {
        resolve(items);
      } else {
        let filteredItems = [];
        items.forEach((item: any, index: number) => {
          new Promise((resolve, reject) => {
            // if(item.street && item.streetNo) {
            // let address = item.street + ' ' + item.streetNo + ', ' + item.plz + ' ' + item.city;
            if (item.address) {
              let address = item.address;
              this.calculate(address, origin)
                .then((response: any) => {
                  resolve({ 'res': response && response.distance ? response.distance.value <= distance : false, 'dis': response.distance });
                })
                .catch(reject);
            } else {
              resolve(false);
            }
          })
            .then((blMatch: any) => {
              if (blMatch.res) {
                item.distance = blMatch.dis.text;
                filteredItems.push(item);
              }
              if (items.length === index + 1) {
                resolve(filteredItems);
              }
            })
            .catch(error => {
              if (error) {
                console.warn('error', error);
              }
              if (items.length === index + 1) {
                resolve(filteredItems);
              }
            });
        });
      }

    });
  }

  geocodeAddress(address: string) {
    this.geocoder = new google.maps.Geocoder;
    return new Promise((resolve, reject) => {
      // address = address.replace(', Deutschland', '') + ', Deutschland';


      this.geocoder.geocode({ 'address': address }, (results: any, status: any) => {
        if (status === 'OK') {
          if (results[0]) {
            let address: any = {};
            if (results[0].hasOwnProperty('address_components')) {
              results[0].address_components.forEach((component: any) => {
                address[component.types[0]] = component.long_name;
              });
            }
            results[0].formatted_address = address;
            resolve(results[0]);
          } else {
            reject();
          }
        } else {
          reject();
        }
      });
    });
  }

  geocodeLatLng(latlng: any) {
    this.geocoder = new google.maps.Geocoder;
    return new Promise((resolve, reject) => {
      this.geocoder.geocode({ 'latLng': latlng }, (results: any, status: any) => {
        if (status === 'OK') {
          if (results[0]) {
            let address: any = {};
            if (results[0].hasOwnProperty('address_components')) {
              results[0].address_components.forEach((component: any) => {
                address[component.types[0]] = component.long_name;
              });
            }
            // results[0].formatted_address = address;
            resolve(results[0]);
          } else {
            reject();
          }
        } else {
          reject();
        }
      });
    });
  }


  getApiKey() {
    return this.apiKey;
  }

}
