import { Component, NgZone, OnInit } from '@angular/core';

import { Platform, AlertController, NavController } from '@ionic/angular';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { UserService } from './user.service';
import { NetworkService } from './network.service';
import { WoocommerceextendService } from './woocommerceextend.service';
// import { OneSignal } from '@ionic-native/onesignal/ngx';
import { LogoService } from './logo.service';
import { StoresService } from './stores.service';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';

import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { CouponValidatePage } from './coupon-validate/coupon-validate.page';
import { AppcmsService } from './appcms.service';
import { CheckoutService } from './checkout.service';

import * as moment from 'moment';
import { EventsService } from './events.service';
import { ReviewService } from './review.service';
import { StatisticsService } from './statistics.service';
import { OrdersService } from './orders.service';
import { TranslateService } from '@ngx-translate/core';
import { BasketService } from './basket.service';
import { Location } from '@angular/common';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { ThemeService } from './theme.service';
import { Router } from '@angular/router';

import { Plugins } from '@capacitor/core';
const { App } = Plugins;
//import { getuid } from 'process';
// import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  public appPages = [
    {
      title: 'Filiale suchen',
      url: '/search',
      icon: 'search'
    },
  ];

  public hasUpdated: boolean = false;

  store: store;

  user: user;

  logoImage = '';
  sidebarLogo = '';

  constructor(
    public alertCtrl: AlertController,
    public AppCMS: AppcmsService,
    public checkout: CheckoutService,
    private deeplinks: Deeplinks,
    public events: EventsService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public userService: UserService,
    public network: NetworkService,
    // public oneSignal: OneSignal,
    public ordersService: OrdersService,
    public woocommerce: WoocommerceextendService,
    public logo: LogoService,
    public reviews: ReviewService,
    public stats: StatisticsService,
    public storesService: StoresService,
    public translate: TranslateService,
    public navCtrl: NavController,
    public zone: NgZone,
    public baskeservice: BasketService,
    private screenOrientation: ScreenOrientation,
    private _location: Location,
    public basket: BasketService,
    private appVersion: AppVersion,
    public geolocation: Geolocation,
    public themeService: ThemeService,
    private router: Router,
    // private fcm:FCM
  ) {
    let getUrl = window.location.href;
    let domain = (new URL(getUrl));
    let domainName = domain.hostname;
    this.loadtheme();
    this.user = this.userService.getUser();
    this.storesService.setInStorage(null, true);

    registerLocaleData(localeDe);
    this.translate.setDefaultLang('de');

    this.initializeApp();
    this.initEventSubscriptions();
  }

  ngOnInit(): void {
    console.log("App.Component.ts Running--->>>")
    this.initializeDeepLinking(); 
   }

  initializeDeepLinking() {
    App.addListener('appUrlOpen', (data: any) => {
      console.log("[initializeDeepLinking] Data:", data);
      if (data.url) {
        const url = new URL(data.url); // Parse the URL
        if (url.protocol === 'broetchen:') {
          // Check if the link matches your custom scheme
          if (url.pathname === 'checkout?MollieID') {
            console.log("check Mollie DeepLinking-----> 114",url)
            // const mollieId = url.searchParams.get('MollieID'); // Extract MollieID
            // this.handleSuccess(); // Pass the ID to your success handler
          }else{
            // this.handleCancel()
          }
        }
      }
    });
  }

  loadtheme() {
    this.sidebarLogo = './assets/img/logo1.png';
    this.logoImage = './assets/img/logo_white1.png';
    var darkmode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const bodyTag = document.body;
    if (darkmode) {
      bodyTag.classList.add('dark');
      this.sidebarLogo = './assets/img/logo_white1.png';
    } else {
      bodyTag.classList.remove('dark');
      this.sidebarLogo = './assets/img/logo1.png';
    }
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      darkmode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      const newColorScheme = event.matches ? "dark" : "light";
      if (darkmode) {
        bodyTag.classList.add('dark');
        this.sidebarLogo = './assets/img/logo_white1.png';
      } else {
        bodyTag.classList.remove('dark');
        this.sidebarLogo = './assets/img/logo1.png';
      }
    });

    this.themeService.getTheme()
      .then((response: any) => {
        window.localStorage.setItem('ComapnyName', response.companyName)
        window.localStorage.setItem('Email', response.email)
        window.localStorage.setItem('tenantId', response.rowId)

        // this.themeService.tenantId = response.rowId
        // this.logoImage = response.logo;
        // document.documentElement.style.setProperty(`--ion-color-primary`, response.color);
        // document.documentElement.style.setProperty(`--ion-color-primary-shade`, response.color);
      })
      .catch(error => {
        //this.events.publish('error', error);
      });
    window.localStorage.setItem('sidebarLogo', this.sidebarLogo)
  }



  checkDeliveryOptionsOnResume() {
    let deliveryOptions = this.checkout.getDeliveryOptions();
    if (deliveryOptions && deliveryOptions.date) {
      let date = moment(deliveryOptions.date), diff = moment().diff(date, 'minutes');

      if (diff >= 0) {
        this.events.publish('error', 'Die gewählte Lieferzeit liegt in der Vergangenheit.');
        delete deliveryOptions.date;
        delete deliveryOptions.datetime;
        delete deliveryOptions.time;
        this.checkout.setDeliveryOptions(deliveryOptions);
        // this.navCtrl.navigateRoot('/delivery-options');
      }
    }
  }



  initDeeplinks() {
    this.deeplinks.route({
      '/coupon/validate/:couponCode': CouponValidatePage,
    }).subscribe(match => {
      // match.$route - the route we matched, which is the matched entry from the arguments to route()
      // match.$args - the args passed in the link
      // match.$link - the full link data
    }, nomatch => {
      // nomatch.$link - the full link data
      console.error('Got a deeplink that didn\'t match', nomatch);
    });
  }

  initEventSubscriptions() {
    this.events.subscribe('appcms:user:loggedin', () => {
      this.zone.run(() => {
        this.user = this.userService.getUser() || {};
      });
    });

    this.events.subscribe('appcms:user:loggedout', () => {
      this.zone.run(() => {
        this.user = this.userService.getUser() || {};
      });
    });

    this.events.subscribe('appcms:user:updated', () => {
      this.zone.run(() => {
        this.user = this.userService.getUser() || {};
      });
    });

    this.events.subscribe('store:changed', (store: store) => {
      this.zone.run(() => {
        this.store = store;
      });
    });

    this.events.subscribe('error', async (error: any) => {

      let _handler = (data: any) => { };

      if (error && typeof error !== 'object') {
        error = error.replace('Error: ', '');
        error = error.replace(' [woocommerce_rest_invalid_coupon]', '');

        if (error.indexOf('rest_cannot_view') > 0) {
          error = 'Diese Filiale scheint zurzeit technische Probleme zu haben. Bitte versuche es erneut oder kontaktiere den Support.';
          _handler = (data: any) => {
            this.navCtrl.navigateRoot('/search');
          };
        }
      }

      let alert = await this.alertCtrl.create({
        header: 'Fehler',
        message: error,
        buttons: [
          {
            text: 'OK',
            handler: _handler
          }
        ]
      });
      alert.present();
    });

    this.events.subscribe('alert', async (alertConfig: any) => {
      let _handler = (data: any) => { };
      let alert = await this.alertCtrl.create({
        header: alertConfig.title || 'Fehler',
        message: alertConfig.message || 'Ein unbekannter Fehler ist aufgetreten',
        buttons: alertConfig.buttons || [
          {
            text: 'OK',
            handler: alertConfig.handler || _handler
          }
        ]
      });
      alert.present();
    });
  }

  initPush() {
    //alert("initPush")
    // this.oneSignal.startInit('b22ad144-15eb-4eb0-a475-420e5a1d7f02', '505924463265');
    // this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);
    // this.oneSignal.endInit();
    // to check if we have permission
  }

  initStatistics() {
    this.stats.count();
    let statistics = this.stats.get();

    if (statistics.countLoaded >= 5) {
      setTimeout(() => {
        // this.reviews.showIfShould();
      }, 10 * 1000);
    }
  }

  initializeApp() {
    var darkmode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    this.logoImage = './assets/img/logo_white1.png';
    const bodyTag = document.body;
    this.platform.ready().then(async () => {
      this.splashScreen.hide();
      // this.getLocation();

      this.checkAppVersion();

      this.network.watch();

      this.initStatistics();

      this.initDeeplinks();

      this.platform.resume.subscribe((e: any) => {
        console.trace("resume called", e);
      });

      this.platform.pause.subscribe((e: any) => {
        console.trace("pause called", e);
      });
      this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);


      this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
        if (this._location.isCurrentPathEqualTo('/delivery-type') || this._location.isCurrentPathEqualTo('/search')) {

          // Show Exit Alert!
          this.showExitConfirm();
          processNextHandler();
        } else {

          // Navigate to back page
          this._location.back();

        }

      });

      this.platform.backButton.subscribeWithPriority(5, () => {
        this.alertCtrl.getTop().then(r => {
          if (r) {
            navigator['app'].exitApp();
          }
        }).catch(e => {
        })
      });
    });
  }
  // getToken() {
  //    this.fcm.getAPNSToken().then(token => {
  //     var devicedata = {
  //       appguid:'', 
  //       type:"IOS",
  //       deviceid:token,
  //       isactive:true,
  //     }
  //     this.userService.adddevicetoken(devicedata).subscribe((response)=> {

  //     })
  //   });
  //   this.fcm.getToken().then(token => {
  //     var devicedata = {
  //       appguid:'', 
  //       type:"ANDROID",
  //       deviceid:token,
  //       isactive:true,
  //     }
  //     this.userService.adddevicetoken(devicedata).subscribe((response)=> {

  //     })
  //   });
  // }
  // RegisterDevice(RegisteredDevices: any) {
  //   return new Promise((resolve, reject) => {
  //     this.AppCMS.postPluginData('RegisteredDevicesGoogle', RegisteredDevices)
  //       .then((response: any) => {
  //         if (response.success) {
  //           resolve(response || 'device registered');
  //         } else {
  //           resolve(response || 'device registered');
  //         }
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // }
  checkAppVersion() {
    var Maxversion = 0, Minversion = 0, Patch = 0, version: any;
    this.appVersion.getVersionNumber().then(value => {
      version = value;
      var convertString = version.toString()
      var splitVersion = convertString.split('.')
      if (splitVersion[0] !== undefined) {
        Maxversion = splitVersion[0];
      }
      if (splitVersion[1] !== undefined) {
        Minversion = splitVersion[1];
      }
      if (splitVersion[2] !== undefined) {
        Patch = splitVersion[2];
      }

      var body = {
        "Maxversion": Maxversion,
        "Minversion": Minversion,
        "Patch": Patch
      }
      this.userService.checkVersion(body).then((resp) => {
        if (!resp) {
          this.appVersionModal();
        }
      }).catch((error) => {
      });
    }).catch(err => {
      // alert(err);
    });
  }

  async appVersionModal() {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: 'Mitteilung',
      subHeader: 'Neues Versions-Update verfügbar',
      message: 'Die broetchen.taxi App muss aktualisiert werden, bevor der Bestellvorgang starten kann.',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Aktualisieren',
          handler: () => {
            if (this.platform.is('android')) {
              window.open("https://play.google.com/store/apps/details?id=taxi.broetchen.app", "_system");

            } else {
              // iOS
              window.open("itms-apps://itunes.apple.com/app/1475469394", "_system");
            }
          }
        }
      ]
    });
    await alert.present();
  }

  async getLocation() {
    let watch = this.geolocation.watchPosition();
    watch.subscribe((data) => {

    });
  }

  showExitConfirm() {
    this.alertCtrl.create({
      header: 'App-Beendigung',
      message: 'Möchten Sie die App schließen?',
      backdropDismiss: false,
      buttons: [{
        text: 'Bleibe',
        role: 'cancel',
        handler: () => {
        }
      }, {
        text: 'Ausgang',
        handler: () => {
          navigator['app'].exitApp();
        }
      }]
    })
      .then(alert => {
        alert.present();
      });
  }

  loadStore() {
    this.storesService.getFromStore()
      .then(store => {
        this.store = store || {};
      })
      .catch(error => {
        console.warn('error', error);
      });
  }

  newOrder() {
    this.navCtrl.navigateForward('/delivery-options');
  }

  onMenuDidClose() {
    this.logo.show();
  }

  onMenuDidOpen() {
    this.logo.hide();
  }

  onMenuWillClose() {
    this.logo.show();
  }

  onMenuWillOpen() {
    this.user = this.userService.getUser() || {};
    this.logo.hide();
  }

  profile() {
    this.navCtrl.navigateForward('/profile');
  }

  async search() {
    this.baskeservice.clear();
    this.AppCMS.resetApiUrl();
    this.storesService.setDefault(null);
    this.navCtrl.navigateRoot('/');
  }

}
