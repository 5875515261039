import { Injectable } from '@angular/core';
import { AppcmsService } from './appcms.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class KioskService {

    // private apiUrl = 'https://your-api-endpoint.com'; // Replace with your API URL
    apiUrl: string = environment.apiURL;
    token ?: string;

    constructor(
    public AppCMS: AppcmsService,
    private http: HttpClient
    ) { 
        this.token = window.localStorage.getItem('jwtToken');
    }

    // getContainerAvailibility(data: any): Observable<any> {
    //     const params = new HttpParams()
    //       .set('start', data.start)
    //       .set('end', data.end)
    //       .set('containerId', data.containerId);
    
    //       const headers = new HttpHeaders({
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${this.token}`
    //       });
          
    //       const response = this.http.get(`${this.apiUrl}kiosk/getavailibility`, { params, headers });
    //     return response;
    //   }

      getContainerAvailibility(payload:any) {
        // console.log("payload:",payload);
        const response = this.AppCMS.loadUrl(
            `kiosk/getavailibility?start=${payload.start}&end=${payload.end}&containerId=${payload.containerId}`, {}
        );
        // console.log("response:", response)
        return response;
      }


    // reserveContainer(data : any):Observable<any>{
    //     const headers = new HttpHeaders({
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${this.token}`
    //     });

    //     const options = { headers };
    //     const response = this.http.post(`${this.apiUrl}kiosk/reservecontainer`,  data, options);
    //     return response;
    // }

    reserveContainer(payload:any){
    return new Promise((resolve, reject) => {
        this.AppCMS.postPluginData(`kiosk/reservecontainer`, payload)
        .then((response: any) => {
            if (response) {
                // console.log("response:",response);
                resolve(response);
            } else {
                reject(response.message || 'Ein unbekannter Fehler ist aufgetreten');
            }
        })
        .catch(error => {
            reject(error);
        });
    });
    }

    // bookContainer(data){
    //     const headers = new HttpHeaders({
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${this.token}`
    //     });
    //     const options = { headers };
          
    //     const response = this.http.post(`${this.apiUrl}kiosk/bookcontainer`,  data, options);
    //     return response;
    // }

    bookContainer(payload:any){
        return new Promise((resolve, reject) => {
            this.AppCMS.postPluginData(`kiosk/bookcontainer`, payload)
            .then((response: any) => {
                if (response) {
                    // console.log("response:",response);
                    resolve(response);
                } else {
                    reject(response.message || 'Ein unbekannter Fehler ist aufgetreten');
                }
            })
            .catch(error => {
                reject(error);
            });
        });
        }
    
}