import { Injectable } from '@angular/core';
import { AppcmsService } from './appcms.service';
import { CacheService } from './cache.service';
import { EventsService } from './events.service';
import { ThemeService } from './theme.service';

@Injectable({
  providedIn: 'root'
})
export class TaxiService {

  constructor(
    public AppCMS: AppcmsService,
    public cache: CacheService,
    public theme: ThemeService,
    public events: EventsService,
  ) {

  }

  getSettings(blForceReload: boolean = false) {
    return new Promise((resolve, reject) => {
      let key = 'taxi_settings_' + this.AppCMS.getApiUrl(),
        fromCache: cacheItem = this.cache.get(key, 60);
      // if(fromCache && fromCache.data && !blForceReload) {
      //   let taxiSettings = fromCache.data;

      //   // if(taxiSettings.theme) {
      //   //   this.theme.update(taxiSettings.theme);
      //   // }

      //   resolve(taxiSettings);
      // } else {
      var UID = JSON.parse(window.localStorage.getItem('current_store')).uid;
      this.AppCMS.loadUrl('Calendar/' + UID, {})
        .then((taxiSettings: taxiSettings) => {
          console.log(taxiSettings,"taxiSettings")
          this.events.publish('taxi:settings:updated', taxiSettings);

          // if(taxiSettings.theme) {
          //   this.theme.update(taxiSettings.theme);
          // }

          this.cache.set(key, taxiSettings);
          resolve(taxiSettings);
        })
        .catch(reject);
      // }
    });
  }

}
