import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class NavigationHistoryService {
    private history: string[] = [];

    constructor(private router: Router) {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.history.push(event.url);
            });
    }

    // Method to get the entire history
    getHistory(): string[] {
        return this.history;
    }

    // Method to navigate back
    goBack() {
        if (this.history.length > 1) {
            const lastUrl = this.history[this.history.length - 2];
            this.router.navigateByUrl(lastUrl);
            this.history.pop(); // Remove the current URL from history
        }
    }

    // Method to get the last visited URL
    getLastUrl(): string | undefined {
        return this.history.length > 1 ? this.history[this.history.length - 2] : undefined;
    }
}
