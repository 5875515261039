import { Injectable } from '@angular/core';
import { AppcmsService } from './appcms.service';

import * as moment from 'moment';
import { UserService } from './user.service';
import { BasketService } from './basket.service';
import { AlertController, NavController } from '@ionic/angular';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { NavigationHistoryService } from './navigationHistory.service';


import { environment } from 'src/environments/environment';
import { getUrl } from '@ionic/angular/directives/navigation/stack-utils';


@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  mainApiUrl: string = environment.apiURL;
  ordersKey: string = 'orders';
  orders: order[] = window.localStorage.getItem(this.ordersKey) ? JSON.parse(window.localStorage.getItem(this.ordersKey)) : [];

  plannedKey: string = 'plannedOrders';
  planned: any = window.localStorage.getItem(this.plannedKey) ? JSON.parse(window.localStorage.getItem(this.plannedKey)) : [];

  iCurrentOrder: number;

  tmpOrder: order;



  constructor(
    public alertCtrl: AlertController,
    public AppCMS: AppcmsService,
    public basket: BasketService,
    public navCtrl: NavController,
    public userService: UserService,
    public http: HttpClient,
    private router: Router,
    public navHistory: NavigationHistoryService
  ) {

  }

  addOrder(order: order) {
    return new Promise((resolve, reject) => {
      this.orders.push(order);
      let user = this.userService.getUser() || {};
      if (user.uid) {
        let basketInfo = this.basket.calculateBasketInfo();

        user.classifications = user.classifications || {};

        user.classifications.points = parseFloat(user.classifications.points || 0);
        user.classifications.points += parseFloat(basketInfo.price_a + '');

        user.classifications.orders = user.classifications.orders || [];
        user.classifications.orders.push(order.id);

        this.userService.setUser(user, true).then(resolve).catch(reject);
      } else {
        this.setOrders(this.orders);
        resolve({});
      }
    });
  }

  addPlannedOrder(order: order) {
    order.items = order.items || [];
    this.planned.push(order);

    let user = this.userService.getUser() || {};

    if (user.uid) {
      let basketInfo = this.basket.calculateBasketInfo();

      user.classifications = user.classifications || {};

      user.classifications.points = parseFloat(user.classifications.points || 0);
      user.classifications.points += parseFloat(basketInfo.price_a + '');

      user.classifications.planned = user.classifications.planned || [];
      user.classifications.planned.push(order.id || order.uid);

      this.userService.setUser(user, true);
    }

    return this.setPlanned(this.planned);
  }

  addToPlannedOrder(product: product, iCurrentOrder: number = null) {
    return new Promise((resolve, reject) => {
      this.planned[iCurrentOrder].items.push(product);
      this.setPlanned(this.planned);
      resolve(this.planned[iCurrentOrder]);
    });
  }

  createTmpOrder() {
    return this.setTmpOrder({});
  }


  getAll() {

    return new Promise((resolve, reject) => {
      try {
        const user = window?.localStorage.getItem('appcmsUser');
        const uid = user ? JSON.parse(user)?.uid : undefined;
        if (!uid) {
          return
        }
        // Set the redirect URL after login

        const token = window.localStorage.getItem('jwtToken');

        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        });

        // Make the HTTP GET request
        this.http.get(this.mainApiUrl + 'Order/Member/' + uid, { headers })
          .subscribe(
            (response: any) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
      } catch (e) {
        reject(e.message);
      }
    });
  }

  // order/GetCancellationInvoicePdfHtml?orderId=6810
  GetCancellationInvoicePdfHtml(id) {
    return new Promise((resolve, reject) => {
        this.AppCMS.loadUrl(`order/GetCancellationInvoicePdfHtml?orderId=${id}`, {})
        .then((value: any) => {
          resolve(value);
        })
        .catch(reject);
    });
  }

  changeRecurringStatus(data) {

    // return new Promise((resolve, reject) => {      
    //   this.AppCMS.putPluginData('RecurringOrder', data).then(resolve).catch(reject);      
    // });
    return new Promise((resolve, reject) => {

      try {
        const token = window.localStorage.getItem('jwtToken');
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        });
        this.http.put(this.mainApiUrl + 'RecurringOrder', data, { headers })
          .subscribe((response: any) => {
            resolve(response);
          });
      } catch (e) {
        // alert(e)
        reject(e.message);
      }



    });
  }

  changeUniqueStatus(data) {
    return new Promise((resolve, reject) => {
      this.AppCMS.putPluginData('Order/updateOrderStatus', data).then(resolve).catch(reject);
    });
  }

  getByTime(time: number, deviceId: number) {
    return new Promise((resolve, reject) => {
      if (!deviceId) {
        reject('missing_device_uid');
      } else {
        this.AppCMS.loadPluginData('devices', {
          time: time,
        }, [deviceId, 'orders']).then(resolve).catch(reject);
      }
    });
  }

  getPlanned() {
    return this.planned;
  }

  getPlannedOrder() {
    return this.planned[this.iCurrentOrder || (this.planned.length - 1)];
  }

  getTmpOrder() {
    return this.tmpOrder;
  }

  setOrders(orders: order[]) {
    this.orders = orders;
    window.localStorage.setItem(this.ordersKey, JSON.stringify(this.orders));
  }

  setPlanned(orders: order[]) {
    this.planned = orders;
    window.localStorage.setItem(this.plannedKey, JSON.stringify(this.planned));
  }

  setPlannedOrder(iCurrentOrder: number) {
    this.iCurrentOrder = iCurrentOrder;
  }

  setTmpOrder(order: order) {
    this.tmpOrder = order;
    return this.tmpOrder;
  }

  async showCreateAlert() {
    let alert = await this.alertCtrl.create({
      header: 'Neue Bestellung',
      subHeader: 'Möchtest du eine einmalige oder eine regelmäßige Bestellung aufgeben?',
      buttons: [
        {
          text: 'Einmalig',
          handler: () => {
            this.navCtrl.navigateForward('/delivery-options');
          },
        },
        {
          text: 'Regelmäßig',
          handler: () => {
            let userId = this.userService.getUid();
            if (userId) {
              this.navCtrl.navigateForward('/planner');
            } else {
              this.showLoginRequiredAlert();
            }
          },
        },
      ]
    });
    alert.present();
  }

  async showLoginRequiredAlert() {
    let alert = await this.alertCtrl.create({
      header: 'Anmeldung erforderlich',
      subHeader: 'Bitte melde Dich an, um regelmäßige Bestellungen aufgeben zu können.',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward('/login');
          },
        },
        {
          text: 'Abbrechen',
          role: 'cancel'
        },
      ]
    });
    alert.present();
  }

}