import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  tmp: any = {};

  constructor() {

  }

  get(key: string, allowedAge: number = 60) {
    let data = window.localStorage.getItem(key);
    let timestamp = this.getTimestamp(key);
    let age = this.getAge(key);



    if (data && (age <= allowedAge)) {

      let jsonData: object;

      try {
        jsonData = JSON.parse(data);
      } catch (e) {
        console.warn('json decode error:', e);
      }

      return {
        age: age,
        data: (jsonData || data),
        timestamp: timestamp,
      };
    }
  }

  getAge(key: string, unit: any = 'minutes') {
    let timestamp = this.getTimestamp(key);
    if (timestamp) {
      return moment().diff(moment.unix(timestamp), unit);
    }
  }

  getTimestamp(key: string) {
    let value = window.localStorage.getItem(key + '_timestamp');
    return value ? parseInt(value) : null;
  }

  getTmp(key: string) {
    return this.tmp[key];
  }

  set(key: string, value: any) {

    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }

    window.localStorage.setItem(key, value);
    window.localStorage.setItem(key + '_timestamp', moment().unix() as any);
  }

  setTmp(key: string, value: any) {
    this.tmp[key] = value;
  }

}
